import storage from "@/utils/storage";

export default {
    //获取购物车缓存，封装添加方法
    getShopCartSto: (value) => {
        var cartList = storage.get("WYC_cartList", true);
        if (cartList) {
            var shopId = value.skuid,
                count = Number(value.number);
            var noRepeat = true;
            cartList.forEach(item => {
                if (item.skuid == shopId) {
                    item.number += count;
                    noRepeat = false;
                }
            });
            if (noRepeat) {
                cartList.push(value);
            }
            storage.set("WYC_cartList", cartList, true);
        } else {
            var WYC_cartList = [];
            WYC_cartList.push(value);
            storage.set("WYC_cartList", WYC_cartList, true);
        }

    }
}