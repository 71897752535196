import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

export const constRouter = [{
        path: '/',
        component: () => import('@/views/home/index'),
        children: [{
                path: 'home',
                name: 'home',
                component: () => import('@/views/home/tab/home'),
            },
            {
                path: 'star',
                name: 'star',
                component: () => import('@/views/home/tab/star'),

            },
            {
                path: 'brandHistory',
                name: 'brandHistory',
                component: () => import('@/views/home/tab/brandHistory'),
            },
            {
                path: 'productDetails',
                name: 'productDetails',
                component: () => import('@/views/home/product/productDetails'),
            },
            {
                path: 'shopCart',
                name: 'shopCart',
                component: () => import('@/views/home/shopCart/shopCart'),
            },
            {
                path: 'personal',
                name: 'personal',
                component: () => import('@/views/home/personal/personal'),
            }, {
                path: 'settlement',
                name: 'settlement',
                component: () => import('@/views/home/settlement/settlement'),
            }, {
                path: 'details/:order_id',
                name: 'details',
                component: () => import('@/views/home/details/details'),
            }, {
                path: 'skinManagement',
                name: 'skinManagement',
                component: () => import('@/views/home/tab/skinManagement'),
            }, {
                path: 'franchiseCooperation',
                name: 'franchiseCooperation',
                component: () => import('@/views/home/tab/franchiseCooperation'),
            }, {
                path: 'homeProducts',
                name: 'homeProducts',
                component: () => import('@/views/home/tab/homeProducts'),
            }, {
                path: 'help',
                name: 'help',
                component: () => import('@/views/home/help/help'),
            }, {
                path: '/searchResult/:text',
                name: 'searchResult',
                component: () => import('@/views/home/search/result'),
            }, {
                path: 'specil',
                name: 'specil',
                component: () => import('@/views/home/fileText/specification'),
            },
            {
                path: 'news',
                name: 'news',
                component: () => import('@/views/home/tab/news'),
            },
            {
                path: 'newsInfo/:id',
                name: 'newsInfo',
                component: () => import('@/views/home/tab/newsInfo'),
            }
        ],
        redirect: '/home',

    },
    {
        path: '/404',
        component: () => import('@/views/404'),
    }
]

export default new Router({
    mode: 'history',
    scrollBehavior: () => ({
        //设置滚动行为，设置跳转滚动条跳转至顶部
        y: 0
    }),
    beforeEach: (to, from, next) => {
        console.log(11111, to, from, next)

    },
    routes: constRouter
})