import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index';
import ElementUI from 'element-ui';
import "babel-polyfill"
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import './theme/index.css'
import 'vant/lib/index.css';
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)
Vue.config.productionTip = false
Vue.use(ElementUI);

import filters from "./plugin/filter";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
import WYfun from "@/utils/funAll.js"
Vue.prototype.WYfun = WYfun

new Vue({
  router,
  store,
  render: h => h(App),
  //添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
  mounted() {
    // document.dispatchEvent(new Event('render-event'))
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount('#app')