<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@media screen and (max-width: 750px) {
  .el-message-box {
    width: 60% !important;
  }
  .el-message {
    min-width: 60% !important;
  }
}

.flex {
  display: flex;
}

.a_c {
  align-items: center;
}

.j_c {
  justify-content: center;
}
.j_s {
  justify-content: space-between;
}
.j_l {
  justify-content: flex-start;
}

.j_r {
  justify-content: flex-end;
}

.noSel {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

li {
  list-style: none;
}

/* 商品加载 */
.shop_loading {
  padding:25px;
}
</style>
