
const storage = {
    //设置本地存储
    set: function(key,value,isJson = false){
        if(key.length == 0 || value.length == 0){
            return false;
        }
        let data = ( isJson == true ? JSON.stringify(value) : value );
        localStorage.setItem(key,data);
    },

    //获取本地存储 
    get: function(key,isJson = false){
        if(key.length == 0){
            return false;
        }
        let data = localStorage.getItem(key);
        return isJson == true ? JSON.parse(data) : data;
    },

    //删除本地存储
    remove: function(key){
        if(key.length == 0){
            return false;
        }
        localStorage.removeItem(key);
    }
}
//抛出
export default storage